import { ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Page/Home";
import ConfiguredTheme from "./theme";
import ChatGPT from "./Page/ChatGPT";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RPG from "./Page/RPG";
import Search from "./Page/Search";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { teachAction } from "./Redux/TeachSlice";
import { useEffect, useState } from "react";
import { gptAction } from "./Redux/GPTSlice"; //

import geturlparams_app from "./geturlparams_app";

function App() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  dispatch(teachAction.addTeach());

  const [rule, setRules] = useState("");

  const fetchB2BRules = async (b2b_id) => {
    try {
      const orderData = {
        b2b_id: b2b_id,

        cmd: "b2b_rule",
      };

      //console.log(orderData);
      // "https://amihud.london/chatgptrule/" + "electra_steps.php",
      const response = await fetch(
        "https://amihud.london/chatgptrule/" + "b2b_rule_get.php", //"rules_select.php"  //"products.php"
        {
          method: "POST", // Specify the method as POST
          headers: {
            "Content-Type": "application/json", // Specify that the content is JSON
          },
          body: JSON.stringify(orderData), // Convert the data to a JSON string
        }
      );

      const data = await response.json();

      // console.log(data);
      if (data.results && data.results[0].qr == 0 && data.code == "200") {
        dispatch(gptAction.toggleShowSend(true)); // change to qr
        dispatch(gptAction.toggleQrMode(false)); // change to qr
      }

      // console.log(savedTeachList);

      if (data.code == "200") {
        dispatch(
          gptAction.toggleAssistantUpload(
            data.results[0].assistant_upload == 0 ? false : true
          )
        );

        dispatch(
          gptAction.toggleAssistant(
            data.results[0].assistant == 0 ? false : true
          )
        );

        setRules(data.results[0]);
      }
    } catch (error) {
      console.error("Error fetching the rules:", error);
    }
  };

  useEffect(() => {
    const urlApp = geturlparams_app();
    // console.log(urlApp);
    if (urlApp.product == null || urlApp.product == 0) {
      dispatch(gptAction.toggleShowSend(true)); // change to qr
      dispatch(gptAction.toggleDemoMode(true)); // change to qr
      dispatch(gptAction.toggleChat(true)); // change to qr
    }

    dispatch(gptAction.toggleB2BHost(urlApp.product)); // change to qr
    dispatch(gptAction.toggleB2BClient(urlApp.b2b_client)); // change to qr

    fetchB2BRules(urlApp.product == null ? 0 : urlApp.product);
  }, []);

  //  {rule != "" && <ChatGPT rule={rule} />}
  return (
    <ThemeProvider theme={ConfiguredTheme}>
      <Layout>
        {rule != "" && (
          <Routes>
            {/*<Route path="/" element={<Navigate to="/chatgpt" />}></Route>*/}
            <Route path="/" element={<ChatGPT rule={rule} />} />
            <Route path="/home/*" element={<Home rule={rule} />} />
            <Route path="/rpg/*" element={<RPG rule={rule} />} />
            <Route path="/newassistant/*" element={<Search rule={rule} />} />
            <Route path="/chatgpt/*" element={<ChatGPT rule={rule} />} />
          </Routes>
        )}

        {rule == "" && (
          <Routes>
            {/*<Route path="/" element={<Navigate to="/chatgpt" />}></Route>*/}
            <Route path="/" element={<Home rule={rule} />} />
          </Routes>
        )}
      </Layout>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </ThemeProvider>
  );
}

export default App;
