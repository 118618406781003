export const geturlparams_app = () => {
  let str = window.location.href;
  let params = new URL(str).searchParams;

  //console.log('params:'+JSON.stringify(params))

  return {
    product: params.get("product"),
    b2b_client: params.get("b2b_client"),
    reorder: params.get("reorder"),
    staff_id: params.get("staff_id"),
    job_id: params.get("job_id"),
  };
};

export default geturlparams_app;

/*
let str = window.location.href;
let params = new URL(str).searchParams;
//console.log('geturlparams params:'+JSON.stringify(params))
str = str.split("/");
const url_id = str[str.length - 1];
console.log("utils geturlparams:" + str);

const IdFromURL = params.get("id");
const PassFromURL = params.get("p");
console.log("utils id:" + IdFromURL);
console.log("utils p:" + PassFromURL);
return { id: IdFromURL, p: PassFromURL };*/
