import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import geturlparams_app from "../../geturlparams_app";

import LeftDrawer from "../ChatGPT/LeftDrawerAssistant";

import RightDrawer from "../ChatGPT/RightDraw";

const StyledCover = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingBlock: 0,
    backgroundColor: "#242526",
    height: "100vh",
  },
  color: "white",
  height: "calc(100vh - 70px)",
  minWidth: "100vw",
  maxWidth: "100vw",
  margin: 0,
  paddingInline: 0,
  paddingBlock: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const OpenAIFileSearch = (props) => {
  const [assistantId, setAssistantId] = useState(null);
  const [vectorStoreId, setVectorStoreId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [nameAssistant, setNameAssistant] = useState(null);

  const [response, setResponse] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileIds, setFileIds] = useState(null);

  const [fileNames, setFileNames] = useState(null);

  const [threadID, setThreadID] = useState(null);

  const [assistants, setAssistants] = useState([]); // Store assistants here

  const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY; // Replace with your OpenAI API key
  const API_URL = "https://api.openai.com/v1";

  const getAssistant = async () => {
    try {
      const urlApp = geturlparams_app();
      //  console.log(urlApp);

      const Info = {
        cmd: "get_assistant",
        b2b_id: urlApp.product,
      };
      let urlBase =
        localStorage.getItem("phpServer") + "/api/assistant_get.php";
      const resAssistant = await fetch(urlBase, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Info),
      });

      const jsonResponse = await resAssistant.json();

      //   console.log(jsonResponse);
      setAssistants(jsonResponse.results);
    } catch (error) {
      console.error("Failed to fetch B2B data:", error);
    }
  };

  const updateAssistant = async (id) => {
    try {
      const Info = {
        cmd: "delete_assistant",
        id: id,
      };
      let urlBase = localStorage.getItem("phpServer") + "/api/assitant_set.php";
      const resAssistant = await fetch(urlBase, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Info),
      });

      const jsonResponse = await resAssistant.json();

      //   console.log(jsonResponse);
      await getAssistant();
    } catch (error) {
      console.error("Failed to fetch B2B data:", error);
    }
  };

  useEffect(() => {
    const getAssistant = async () => {
      try {
        const urlApp = geturlparams_app();
        //  console.log(urlApp);

        const Info = {
          cmd: "get_assistant",
          b2b_id: urlApp.product,
        };
        let urlBase =
          localStorage.getItem("phpServer") + "/api/assistant_get.php";
        const resAssistant = await fetch(urlBase, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Info),
        });

        const jsonResponse = await resAssistant.json();

        //   console.log(jsonResponse);
        setAssistants(jsonResponse.results);
      } catch (error) {
        console.error("Failed to fetch B2B data:", error);
      }
    };

    getAssistant();
  }, []); // Empty dependency array: runs only on mount

  useEffect(() => {
    console.log(fileNames);
    console.log(fileIds);
  }, [fileNames]);

  // 1. Create an Assistant with File Search Enabled
  const createAssistant = async () => {
    try {
      setLoading(true); // Indicate the request is in progress

      const res = await axios.post(
        `${API_URL}/assistants`, // API URL
        {
          // Request body
          name: nameAssistant, // Matches the "name" value in the curl
          instructions: "You are personal search file",
          model: "gpt-4-turbo", // Correct model
          tools: [{ type: "file_search" }], // Matches the "tools" array
          tool_resources: {
            file_search: {
              vector_store_ids: [vectorStoreId],
            },
          },
        },
        {
          // Headers
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`, // Authorization token
            "Content-Type": "application/json", // Correct Content-Type
            "OpenAI-Beta": "assistants=v2", // Beta header for assistants v2
          },
        }
      );

      // Store and log the assistant ID
      setAssistantId(res.data.id);

      const threadID = null;
      if (threadID == null) {
        const threadRes = await axios.post(
          `https://api.openai.com/v1/threads`,
          {},
          {
            headers: {
              Authorization: `Bearer ${OPENAI_API_KEY}`,
              "Content-Type": "application/json",
              "OpenAI-Beta": "assistants=v2",
            },
          }
        );

        const threadId = threadRes.data.id;
        console.log("Thread created:", threadId);
        setThreadID(threadId);

        ///========
        const urlApp = geturlparams_app();
        console.log(urlApp);

        const Info = {
          cmd: "add_assistant",
          data: {
            b2b_id: urlApp.product,
            thread_id: threadId,
            assistant_id: res.data.id,
            name: nameAssistant,
          },
        };
        let urlBase =
          localStorage.getItem("phpServer") + "/api/assitant_set.php";
        const resAssistant = await fetch(urlBase, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Info),
        });

        console.log(resAssistant);
        // Check if the response is OK (status code 200-299)
        if (!resAssistant.ok) {
          throw new Error(`HTTP error! Status: ${resAssistant.status}`);
        }

        props.setIsSearch(false);
        /////////////
      }
      console.log("Assistant created:", res.data);
    } catch (error) {
      console.error("Error creating assistant:", error);
    } finally {
      setLoading(false); // Indicate the request is complete
    }
  };

  // 2. Upload Files and Create Vector Store

  const uploadFilesAndCreateVectorStore = async (files) => {
    try {
      setLoading(true);

      // To store all file IDs
      const fileIdsl = [];

      // Loop through each file and upload them one by one
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file); // Use the correct field name
        formData.append("purpose", "assistants"); // Add the 'purpose' field

        // Upload file
        const uploadRes = await axios.post(`${API_URL}/files`, formData, {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "multipart/form-data",
          },
        });

        // Extract file ID from response
        const fileId = uploadRes.data.id;
        if (!fileId) {
          throw new Error(`Failed to upload file: ${file.name}`);
        }

        console.log(`File uploaded successfully: ${fileId}`);
        fileIdsl.push(fileId);
      }

      // Log all uploaded file IDs
      console.log("Uploaded file IDs:", fileIdsl);
      if (fileIds == null) setFileIds(fileIdsl);
      else setFileIds((prevFileIds) => prevFileIds.concat(fileIdsl));

      const names = [];
      for (const file of files) {
        console.log(file.name);
        names.push(file.name);
      }
      if (fileNames == null) setFileNames(names);
      else setFileNames((prevFileName) => prevFileName.concat(names));

      // Create vector store using uploaded file IDs
      /* const vectorStoreRes = await axios.post(
        `${API_URL}/vector_stores`,
        {
          name: "Financial Documents", // Adjust the name as needed
          file_ids: fileIds, // Use the collected file IDs
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2", // Beta header for assistants v2
          },
        }
      );

      console.log("Vector store created successfully:", vectorStoreRes.data);
      setVectorStoreId(vectorStoreRes.data.id);*/
    } catch (error) {
      console.error(
        "Error uploading files or creating vector store:",
        error?.response?.data || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  // 3. Attach the Vector Store to the Assistant
  const attachVectorStoreToAssistant = async () => {
    try {
      setLoading(true);
      const vectorStoreRes = await axios.post(
        `${API_URL}/vector_stores`,
        {
          name: "tencafe_doc", // Adjust the name as needed
          file_ids: fileIds, // Use the collected file IDs
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2", // Beta header for assistants v2
          },
        }
      );

      console.log("Vector store created successfully:", vectorStoreRes.data);
      setVectorStoreId(vectorStoreRes.data.id);
      setNameAssistant(null);
    } catch (error) {
      console.error("Error attaching vector store to assistant:", error);
    } finally {
      setLoading(false);
    }
  };

  const attachVectorStoreToAssistant_didnotwork = async () => {
    try {
      setLoading(true);
      const res = await axios.patch(
        `${API_URL}/assistants/${assistantId}`,
        {
          tool_resources: {
            file_search: {
              vector_store_ids: [vectorStoreId],
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2", // Beta header for assistants v2
          },
        }
      );
      console.log("Vector store attached to assistant:", res.data);
    } catch (error) {
      console.error("Error attaching vector store to assistant:", error);
    } finally {
      setLoading(false);
    }
  };

  // 4. Search the Files using the Assistant

  const searchFiles = async () => {
    try {
      // Step 1: Create a thread
      let threadId = "";
      setLoading(true);
      setResponse("");
      if (threadID == null) {
        const threadRes = await axios.post(
          `https://api.openai.com/v1/threads`,
          {},
          {
            headers: {
              Authorization: `Bearer ${OPENAI_API_KEY}`,
              "Content-Type": "application/json",
              "OpenAI-Beta": "assistants=v2",
            },
          }
        );

        threadId = threadRes.data.id;
        console.log("Thread created:", threadId);
        setThreadID(threadId);

        ///========
        const urlApp = geturlparams_app();
        console.log(urlApp);

        const Info = {
          cmd: "add_assistant",
          data: {
            b2b_id: urlApp.product,
            thread_id: threadId,
            assistant_id: assistantId,
            name: nameAssistant,
          },
        };
        let urlBase =
          localStorage.getItem("phpServer") + "/api/assitant_set.php";
        const resAssistant = await fetch(urlBase, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Info),
        });

        console.log(resAssistant);
        // Check if the response is OK (status code 200-299)
        if (!resAssistant.ok) {
          throw new Error(`HTTP error! Status: ${resAssistant.status}`);
        }

        /////////////
      } else threadId = threadID;
      // Step 2: Add a message and associate tools (vector store)
      const messageRes = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          // Only one message is added at a time
          role: "user", // Add the role for this message
          content: searchQuery, // The search query or input
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      console.log(
        "Message and tool resources added to thread",
        messageRes.data
      );

      // Step 3: Process the thread (Run the assistant with the given query and vector store)
      const runRes = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        {
          assistant_id: assistantId,
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      console.log("Search response:", runRes.data);

      let runRetRes = "";
      runRetRes = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/runs/${runRes.data.id}`,

        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      while (runRetRes.data.status != "completed") {
        runRetRes = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/runs/${runRes.data.id}`,

          {
            headers: {
              Authorization: `Bearer ${OPENAI_API_KEY}`,
              "Content-Type": "application/json",
              "OpenAI-Beta": "assistants=v2",
            },
          }
        );

        console.log("Search response:", runRetRes.data);
        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await wait(500); // Waits for 5 seconds
      }

      fetchMessages(threadId);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error?.response?.data || error.message);
    }
  };

  const searchFilesReuseThread = async (assistantID) => {
    try {
      // Step 1: Create a thread
      setLoading(true);
      setResponse("");
      const threadRes = await axios.post(
        `https://api.openai.com/v1/threads`,
        {},
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );
      const threadId = threadRes.data.id;
      console.log("Thread created:", threadId);

      // Step 2: Add a message and associate tools (vector store)
      const messageRes = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          // Only one message is added at a time
          role: "user", // Add the role for this message
          content: searchQuery, // The search query or input
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      console.log(
        "Message and tool resources added to thread",
        messageRes.data
      );

      // Step 3: Process the thread (Run the assistant with the given query and vector store)
      const runRes = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        {
          assistant_id: assistantId,
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      console.log("Search response:", runRes.data);

      let runRetRes = "";
      runRetRes = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/runs/${runRes.data.id}`,

        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      while (runRetRes.data.status != "completed") {
        runRetRes = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/runs/${runRes.data.id}`,

          {
            headers: {
              Authorization: `Bearer ${OPENAI_API_KEY}`,
              "Content-Type": "application/json",
              "OpenAI-Beta": "assistants=v2",
            },
          }
        );

        console.log("Search response:", runRetRes.data);
        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await wait(500); // Waits for 5 seconds
      }

      fetchMessages(threadId);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error?.response?.data || error.message);
    }
  };

  const fetchMessages = async (threadId) => {
    try {
      const msgRes = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      console.log("Messages:", msgRes.data);

      const assistantMessage = msgRes.data.data.find(
        (message) => message.role === "assistant"
      );

      // Extract the text content
      const assistantText = assistantMessage?.content[0]?.text?.value;

      setResponse(assistantText);
      return msgRes.data;
    } catch (error) {
      console.error(
        "Error fetching messages:",
        error.response?.data || error.message
      );
    }
  };

  // Function to handle when an assistant name is clicked
  const handleAssistantClick = (assistant) => {
    setAssistantId(assistant.assistant_id);
    setVectorStoreId(assistant.assistant_id);
    setNameAssistant(assistant.name);
    setResponse("");
    setSearchQuery("");

    console.log("Assistant clicked:", assistant);
  };

  const handleDeleteAssistant = (id) => {
    // Implement the deletion logic here
    const updatedAssistants = assistants.filter(
      (assistant) => assistant.id !== id
    );
    setAssistants(updatedAssistants);
    updateAssistant(id);
  };

  return (
    <StyledCover id="cover">
      <Grid container sx={{ height: "100%" }} id="container">
        <Grid
          item
          sx={{ display: { xs: "none", md: "block" } }}
          md={0.5}
        ></Grid>

        <Grid
          item
          md={11}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            sx={{
              p: 4,
              maxWidth: 1200,
              mx: "auto",
              backgroundColor: "#000", // Black background
              color: "#fff", // White text
              //  minHeight: "100vh", // Full screen height
              borderRadius: 2,
              position: "relative", // Needed for absolute positioning of children
            }}
          >
            {/* Top-left corner */}
            <Box
              sx={{
                position: "absolute",
                top: 16,
                left: 30,
                zIndex: 10,
              }}
            >
              <LeftDrawer />
            </Box>

            {/* Header */}
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              sx={{ color: "#fff" }}
            >
              OpenAI File Search Assistant
            </Typography>

            {/* Top-right corner */}
            <Box
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                zIndex: 10,
              }}
            >
              <RightDrawer />
            </Box>

            <Grid container spacing={3} sx={{ height: "100%" }}>
              {/* Left Column */}
              <Grid item xs={12} md={3}>
                <Paper elevation={3} sx={{ p: 2, backgroundColor: "#1a1a1a" }}>
                  <Typography variant="h6" gutterBottom>
                    Available Assistants
                  </Typography>
                  <TableContainer sx={{ maxHeight: 300, overflowY: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: "#fff" }}>
                            Assistant Name
                          </TableCell>
                          <TableCell
                            sx={{ color: "#fff", textAlign: "center" }}
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {assistants.map((assistant) => (
                          <TableRow key={assistant.id} hover>
                            <TableCell sx={{ color: "#fff" }}>
                              {assistant.name}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              <IconButton
                                color="error"
                                onClick={() =>
                                  handleDeleteAssistant(assistant.id)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>

              {/* Right Column */}
              <Grid item xs={12} md={9}>
                <Grid container spacing={3}>
                  {/* Upload Files */}
                  <Grid item xs={12}>
                    <Paper
                      elevation={3}
                      sx={{ p: 2, backgroundColor: "white" }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Step 1: Upload Files
                      </Typography>
                      <input
                        type="file"
                        multiple
                        onChange={(e) =>
                          uploadFilesAndCreateVectorStore(e.target.files)
                        }
                        disabled={loading || assistantId}
                        style={{ marginBottom: 16 }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2, // Space between items
                          mt: 2, // Margin top
                        }}
                      >
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <InputLabel id="uploaded-files-label"></InputLabel>
                          {fileNames && (
                            <Select
                              labelId="uploaded-files-label"
                              value=""
                              displayEmpty
                              renderValue={() =>
                                fileNames.length > 0
                                  ? "files :" + fileNames.length
                                  : "No files uploaded"
                              }
                            >
                              {fileNames.map((fileName, index) => (
                                <MenuItem key={index} value={fileName}>
                                  {fileName}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={attachVectorStoreToAssistant}
                          fullWidth
                          disabled={
                            loading || assistantId || !fileIds || vectorStoreId
                          }
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Attach Vector Store"
                          )}
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>

                  {/* Create Assistant */}
                  <Grid item xs={12}>
                    <Paper
                      elevation={3}
                      sx={{ p: 2, backgroundColor: "white" }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Step 2: Create Assistant
                      </Typography>

                      <TextField
                        fullWidth
                        label="Enter Name"
                        variant="outlined"
                        value={nameAssistant}
                        onChange={(e) => setNameAssistant(e.target.value)}
                        sx={{ mb: 2, input: { color: "#000" } }}
                        InputLabelProps={{ style: { color: "#000" } }}
                        disabled={loading || assistantId || !vectorStoreId}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={createAssistant}
                        fullWidth
                        disabled={
                          loading ||
                          !vectorStoreId ||
                          assistantId ||
                          !nameAssistant
                        }
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Create Assistant"
                        )}
                      </Button>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          item
          sx={{ display: { xs: "none", md: "block" } }}
          md={0.5}
        ></Grid>
      </Grid>
    </StyledCover>
  );
};

export default OpenAIFileSearch;
