import MenuIcon from "@mui/icons-material/Menu";
import { Button, Divider, Drawer, Fab, Stack, TextField } from "@mui/material";
import { useState } from "react";
import Conversation from "./Conversation";
import ListConversation from "./ListConversation";
import ListTeach from "./ListTeach";

function LeftDrawer({ name }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        sx={{
          position: "absolute",
          left: "50%",

          color: "white",
          top: 5,
          zIndex: 999,
          opacity: 1,
        }}
        size="small"
      >
        {name}
      </Button>
    </>
  );
}

export default LeftDrawer;
