import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import useHTTPGPT from "../../HTTP_Request/useHTTPGPT";
import ChatBoard from "./ChatBoard";
import ChatBoardAssistant from "./ChatBoardAssistant";
import ChatForm from "./ChatForm";
//import LeftDrawer from "./LeftDrawer";
import LeftDrawer from "./LeftDrawerRule";
import NewUpdate from "./NewUpdate";
import RightDrawer from "./RightDraw";
import MidDrawer from "./MidDraw";

import useSpeak from "./RightDraw/Setting/Speaker/useSpeak";
import ScrollDownButton from "./ScrollDownButton";

import { teachAction } from "../../Redux/TeachSlice";
import geturlparams_app from "../../geturlparams_app";

import axios from "axios";

import { useNavigate } from "react-router-dom";

const StyledCover = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingBlock: 0,
    backgroundColor: "#242526",
    height: "100vh",
  },
  color: "white",
  height: "calc(100vh - 70px)",
  minWidth: "100vw",
  maxWidth: "100vw",
  margin: 0,
  paddingInline: 0,
  paddingBlock: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const StyledChatBox = styled(Box)(({ theme }) => ({
  height: "calc(87vh - 70px)",
  width: "90vw",
  maxWidth: "90vw",
  color: "white",
  margin: 0,
  borderRadius: "15px",
  paddingBlock: "35px",
  paddingInline: "20px",
  backgroundColor: "#242526",
  [theme.breakpoints.down("sm")]: {
    minWidth: "100vw",
    maxWidth: "100vw",
    height: "83vh",
    paddingBlock: "0px",
    paddingInline: "0px",
  },
  position: "relative",
}));

const ChatGPT = (props) => {
  const speaker = useSpeak();
  const speak = useSelector((redux) => redux.speak);
  const teach = useSelector((redux) => redux.teach);
  const gptAPI = useHTTPGPT();

  /* const [messages, setMessages] = useState(
    JSON.parse(localStorage.getItem("messages1")) || []
  ); // amihud change to message1 , messages need to be read from database avvording the user verification
*/
  const [messages, setMessages] = useState([]); // amihud change to message1 , messages need to be read from database avvording the user verification

  const [menu, setMenu] = useState(false);
  const [rules, setRules] = useState();
  const [summery, setSummery] = useState();

  const [chatIndex, serChatIndex] = useState(0);
  //const [assistantId, setAssistantId] = useState(null);
  const [assistants, setAssistants] = useState([]); // Store assistants here

  const [seachAction, setSeachAction] = useState(false);

  //const urlApp = geturlparams_app();
  //const [b2b_id, setB2b_id] = useState(urlApp.product);
  const [b2b_id, setB2b_id] = useState(props.rule.b2b_id);
  const [php, setPHP] = useState(props.rule.php);

  const [dbChatID, setDbChatID] = useState(0);

  const [assistantLoading, setSssistantLoading] = useState(false);

  const [currentJob, setCurrentJob] = useState("");

  const dispatch = useDispatch();

  const demoMode = useSelector((redux) => redux.gpt.demoMode);
  const threadId = useSelector((redux) => redux.gpt.threadID); // change to send
  const assistantId = useSelector((redux) => redux.gpt.assistantID); // change to send
  const chat = useSelector((redux) => redux.gpt.chat); // change to send
  const assistant = useSelector((redux) => redux.gpt.assistant); // change to send

  const assistantName = useSelector((redux) => redux.gpt.assistantName); // change to send

  const b2bHost = useSelector((redux) => redux.gpt.b2b_host);
  const b2bClient = useSelector((redux) => redux.gpt.b2b_client);

  const listTeachActive = useSelector((redux) => redux.teach);

  const showSend = useSelector((redux) => redux.gpt.showSend); // changed to send

  // console.log(assistant);
  // console.log(chat);
  //console.log(teach);
  //console.log(props);

  //    const urlApp = geturlparams_app();
  //    console.log(urlApp);

  //////////Assistant ////////

  const fetchMessages = async (threadId) => {
    try {
      const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY; // Replace with your OpenAI API key

      const msgRes = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      console.log("Messages:", msgRes.data);

      const assistantMessage = msgRes.data.data.find(
        (message) => message.role === "assistant"
      );

      // Extract the text content
      const assistantText = assistantMessage?.content[0]?.text?.value;
      console.log(assistantText);
      //  setResponse(assistantText);

      // return msgRes.data;

      return Promise.resolve(assistantText);
    } catch (error) {
      console.error(
        "Error fetching messages:",
        error.response?.data || error.message
      );
    }
  };

  const searchFiles = async (searchQuery) => {
    try {
      setSssistantLoading(true);
      // Step 1: Create a thread
      const message = {
        id: uuidv4(),
        role: "user",
        content: searchQuery,
      };
      let newMessages = [...messages, message];
      setMessages(newMessages);

      const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY; // Replace with your OpenAI API key

      // const threadId = ""; //threadID;
      // setLoading(true);
      // setResponse("");

      // Step 2: Add a message and associate tools (vector store)
      const messageRes = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          // Only one message is added at a time
          role: "user", // Add the role for this message
          content: searchQuery, // The search query or input
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      console.log(
        "Message and tool resources added to thread",
        messageRes.data
      );

      // Step 3: Process the thread (Run the assistant with the given query and vector store)
      const runRes = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        {
          assistant_id: assistantId,
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      console.log("Search response:", runRes.data);

      let runRetRes = "";
      runRetRes = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/runs/${runRes.data.id}`,

        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      while (runRetRes.data.status != "completed") {
        runRetRes = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/runs/${runRes.data.id}`,

          {
            headers: {
              Authorization: `Bearer ${OPENAI_API_KEY}`,
              "Content-Type": "application/json",
              "OpenAI-Beta": "assistants=v2",
            },
          }
        );

        console.log("Search response:", runRetRes.data);
        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await wait(500); // Waits for 5 seconds
      }

      const assistantText = await fetchMessages(threadId);

      const val = {
        content: assistantText,
        id: uuidv4(),
        role: "assistant",
      };
      //val.contant = response.content.summery_prompt;
      console.log(val);
      newMessages = [...messages, message, val];
      setMessages(newMessages);
      setSssistantLoading(false);
      // setLoading(false);
    } catch (error) {
      setSssistantLoading(false);
      console.error("Error:", error?.response?.data || error.message);
    }
  };

  ///////////

  useEffect(() => {
    console.log(listTeachActive);
    const savedTeachList = localStorage.getItem("teachList");
    const InitialState = savedTeachList ? JSON.parse(savedTeachList) : [];

    // console.log(teach);
    console.log(InitialState);

    //const urlApp = geturlparams_app();
    //console.log(urlApp.product);
    //setB2b_id(urlApp.product);

    fetchMachinesRules(InitialState);
  }, [menu == false && teach.length == 1 && teach[0].content == ""]);

  useEffect(() => {
    for (let i = 0; i < teach.length - 1; i++)
      dispatch(teachAction.removeTeach(teach[i].id));
  }, [teach.length > 1]);

  const fetchMachinesRules = async (savedTeachList) => {
    try {
      const orderData = {
        serial_id: 1,
        machine_name: 2,
        b2b_id: b2b_id,
        customer_name: 4,
        cmd: "error_check",
      };

      // console.log(orderData);
      // "https://amihud.london/chatgptrule/" + "electra_steps.php",
      const response = await fetch(
        "https://amihud.london/chatgptrule/" + php, //"rules_select.php"  //"products.php"
        // "https://amihud.london/chatgptrule/" + "rules_select.php", //"rules_select.php"  //"products.php"
        {
          method: "POST", // Specify the method as POST
          headers: {
            "Content-Type": "application/json", // Specify that the content is JSON
          },
          body: JSON.stringify(orderData), // Convert the data to a JSON string
        }
      );

      const data = await response.json();

      //console.log(data);
      // console.log(savedTeachList);
      localStorage.setItem(
        "rulesList",
        JSON.stringify(data.rules_data)
        /*  JSON.stringify([
          { id: 1, content: "Steps", php: "electra_steps.php", json: 0 },
          { id: 2, content: "Info", php: "electra.php", json: 0 },
          { id: 3, content: "Current Order", php: "current_order.php", json: 1 },
        ])*/
      ); /////  need to be read from DB according the customer setting

      const getRulesList = JSON.parse(localStorage.getItem("rulesList"));
      // console.log(getRulesList);

      setRules(data.rules);

      dispatch(
        teachAction.updateTeach({
          id: savedTeachList[0].id,
          content: data.rules,
          rule_id: data.rules_data[0].id,
          json: data.rules_data[0].json,
        })
      );
      setSeachAction(true);
    } catch (error) {
      console.error("Error fetching the rules:", error);
    }
  };

  const saveChat = async (cmd, data, dbChatID) => {
    try {
      const urlApp = geturlparams_app();
      //  console.log(urlApp);

      const Info = {
        cmd: cmd,
        data: data,
      };
      let urlBase = localStorage.getItem("phpServer") + "/api/ai_chat_set.php";
      const chat = await fetch(urlBase, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Info),
      });

      const jsonResponse = await chat.json();

      //console.log(jsonResponse);
      return Promise.resolve(jsonResponse);

      //setAssistants(jsonResponse.results);
    } catch (error) {
      console.log(chat);
      console.error("Failed to fetch B2B data:", error);
    }
  };

  useEffect(() => {
    console.log(messages);
    const saveChatProcess = async () => {
      localStorage.setItem("messages", JSON.stringify(messages));
      if (messages.length == 1) {
        const conv = { b2b_id: b2bClient, title: messages[0].content };
        const chatSubject = await saveChat("add_chat_subject", conv, 0);
        //console.log(chatSubject.result.category_id);
        setDbChatID(chatSubject.result.category_id);

        const convs = {
          b2b_id: b2bClient,
          message: messages[0].content,
          sender: messages[0].role,
          conversation_id: chatSubject.result.category_id,
        };

        await saveChat("add_chat_converstion", convs);

        serChatIndex((prevIndex) => {
          if (prevIndex !== 0) {
            return 1;
          }
          return prevIndex + 1; // Increment if it is 0
        });
        // serChatIndex((prevIndex) => prevIndex + 1);
        serChatIndex((prevIndex) => (prevIndex !== 0 ? 1 : prevIndex + 1));
      } else {
        console.log(chatIndex);
        const conv = {
          b2b_id: b2bClient,
          message: messages[chatIndex].content,
          sender: messages[chatIndex].role,
          conversation_id: dbChatID,
        };

        await saveChat("add_chat_converstion", conv);
        serChatIndex((prevIndex) => prevIndex + 1);
      }
    };
    if (messages.length > 0) saveChatProcess();
  }, [messages]);

  useEffect(() => {
    if (assistant == true) {
      setCurrentJob(assistantName);
    } else setCurrentJob(teach[0].rule);
  }, [assistant && assistantName]);

  useEffect(() => {
    //setCurrentJob(activeTeach.content);

    const listTeach = JSON.parse(localStorage.getItem("rulesList")) || [];
    /*const activeTeach = listTeach.find(
      (obj) => obj.id == listTeachActive[0].rule_id
    );*/
    const activeTeach = listTeach.find((obj) =>
      listTeachActive.some((activeObj) => activeObj.rule_id === obj.id)
    );

    if (activeTeach && chat) setCurrentJob(activeTeach.content);

    console.log(activeTeach);
  }, [chat]);

  const getServerResult = async (contentsString) => {
    const content = JSON.parse(contentsString);
    console.log("Request content:", content);
    let urlBase = localStorage.getItem("phpServer") + "/api/serverAIApi.php";

    try {
      // const res = await fetch("https://nahat.co.uk/nahat/api/serverAIApi.php", {
      const res = await fetch(urlBase, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(content),
      });

      //console.log(res);
      // Check if the response is OK (status code 200-299)
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      // Parse the JSON response
      const parsedContent = await res.json();
      console.log("Parsed content:", parsedContent);

      return parsedContent;
    } catch (e) {
      console.error("Error in getServerResult:", e.message || e);
    }

    // Return null or some default value if there's an error
    return null;
  };

  function isJson(str) {
    if (typeof str !== "string") return false;
    str = str.trim();

    // Check if it starts with '{' or '['
    if (str.startsWith("{") || str.startsWith("[")) {
      try {
        // Attempt to fix missing quotes around keys using regex
        const fixedStr = str.replace(
          /([{,])\s*([a-zA-Z0-9_]+)\s*:/g,
          '$1"$2":'
        );
        JSON.parse(fixedStr);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  async function handleSubmitJson(content) {
    console.log("handleSubmitJson");
    const message = {
      id: uuidv4(),
      role: "user",
      content: content,
    };
    let newMessages = [...messages, message];
    setMessages(newMessages);

    try {
      const newHistory = teach
        .map((t) => ({ role: "system", content: t.content }))
        .concat(
          newMessages.slice(-8).map((message) => ({
            role: message.role,
            content: message.content,
          }))
        );

      console.log(newHistory);

      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: newHistory,
            temperature: 0.25,
          }),
        }
      ).then((res) => res.json());

      const assistantMessage = response.choices[0].message.content;
      console.log(assistantMessage);

      //const res = assistantMessage.replace(/'/g, '"');

      if (isJson(assistantMessage)) {
        // Correctly await the async function
        console.log("this is json");
        const value = await getServerResult(assistantMessage);
        console.log("Server response:", value);
        let view = "";
        if (value.view == "text") view = value.results;
        else if (value.view == "ai") view = "Data loaded";
        const messageRes = {
          id: uuidv4(),
          role: "assistant",
          content:
            value.view == "text" ? JSON.stringify(view) : JSON.stringify(value),
        };

        newMessages = [...messages, message, messageRes];
        setMessages(newMessages);

        handleRemove(value.results);
      } else {
        const messageRes = {
          id: uuidv4(),
          role: "assistant",
          content: assistantMessage,
        };

        newMessages = [...messages, message, messageRes];
        setMessages(newMessages);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function getSummeyByChat(content, data) {
    const message = {
      id: uuidv4(),
      role: "user",
      content: data,
    };
    let newMessages = [message];

    // console.log(newMessages);

    //console.log(data);

    const teach = [{ content: content }];
    try {
      const newHistory = teach
        .map((t) => ({ role: "system", content: t.content }))
        .concat(
          newMessages.slice(-8).map((message) => ({
            role: message.role,
            content: message.content,
          }))
        );

      console.log(newHistory);

      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // model: "gpt-3.5-turbo",   //gpt-4
            model: "gpt-3.5-turbo",
            messages: newHistory,
            temperature: 0.25,
          }),
        }
      ).then((res) => res.json());

      const assistantMessage = response.choices[0].message.content;
      console.log(assistantMessage);
      setSummery(assistantMessage);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const fetchMachinesRulesChat = async (
    id,
    php,
    rule_id,
    json,
    summery_prompt,
    info
  ) => {
    try {
      const qrInfo = JSON.parse(localStorage.getItem("qrInfo"));
      const bQr = qrInfo == "" ? 0 : 1;

      const orderData = {
        //add local client -- amihud 12-1-2025
        serial_id: 1,
        machine_name: 2,
        b2b_id: b2b_id,
        customer_name: 4,
        info: info,
        bQr: bQr,
        qrInfo: qrInfo,

        cmd: "error_check",
      };
      // console.log(orderData);
      // console.log(php);
      const response = await fetch("https://amihud.london/chatgptrule/" + php, {
        method: "POST", // Specify the method as POST
        headers: {
          "Content-Type": "application/json", // Specify that the content is JSON
        },
        body: JSON.stringify(orderData), // Convert the data to a JSON string
      });

      const data = await response.json();

      //  console.log(data);
      //console.log(id);

      dispatch(
        teachAction.updateTeach({
          id: id,
          content: data.rules, //+ " " + rules,
          rule_id: rule_id,
          json: json,
        })
      );

      setMessages([]); //15-12-2024
      //console.log(summery_prompt);
      //getSummeyByChat(summery_prompt, data.rules);
    } catch (error) {
      console.error("Error fetching the rules:", error);
    }
  };

  function handleRemove(info) {
    console.log(info);
    console.log(teach); /// this is teach[0].id current job rule menue
    setMenu(true);
    for (let i = 0; i < teach.length - 1; i++)
      dispatch(teachAction.removeTeach(teach[i].id));

    const listTeach = JSON.parse(localStorage.getItem("rulesList")) || [];
    /*  const activeTeach = listTeach.find(
      (obj) => obj.id == listTeachActive[0].rule_id
    );*/
    console.log(listTeachActive); // one element
    console.log(listTeach); // all rules
    /*const activeTeach = listTeach.find((obj) =>
      listTeachActive.some((activeObj) => activeObj.rule_id === obj.id)
    );*/

    const activeTeach = listTeach.find((obj) => obj.id == info.id);
    console.log(activeTeach);

    setCurrentJob(activeTeach.content);

    fetchMachinesRulesChat(
      teach[0].id,
      info.php,
      info.id,
      info.json,
      info.summery_prompt,
      info
    );
  }

  /*function fixJsonString(str) {
    // Trim the input string and handle missing quotes around keys
    let fixedStr = str
      .trim()
      .replace(/([{,])\s*([a-zA-Z0-9_]+)\s*:/g, '$1"$2":') // Add missing quotes around keys
      .replace(/,\s*$/, ""); // Remove trailing comma if present

    // Check if the JSON string is missing a closing brace
    if (fixedStr[fixedStr.length - 1] !== "}") {
      fixedStr += "}";
    }

    return fixedStr;
  }

  function tryParseJson(str) {
    //console.log(str);
    const fixedStr = fixJsonString(str);
    try {
      return JSON.parse(fixedStr);
    } catch (error) {
      console.error("JSON parse error:", error);
      return null;
    }
  }*/

  /*
  function fixJsonString(str) {
    // Trim the input string and handle missing quotes around keys
    let fixedStr = str
      .trim()
      .replace(/([{,])\s*([a-zA-Z0-9_]+)\s*:/g, '$1"$2":') // Add missing quotes around keys
      .replace(/,\s*$/, ""); // Remove trailing comma if present

    // Add missing quotes around string values (e.g., "flag": red → "flag": "red")
    fixedStr = fixedStr.replace(/:\s*([a-zA-Z0-9_]+)([,\}])/g, ': "$1"$2');

    // Fix mismatched or missing quotes (e.g., {"id":"value",flag":"red"} → {"id":"value","flag":"red"})
    fixedStr = fixedStr.replace(/(["'])([^"':,{}\[\]]+)(["'])/g, '"$2"');

    // Check if the JSON string is missing a closing brace
    if (fixedStr[fixedStr.length - 1] !== "}") {
      fixedStr += "}";
    }

    return fixedStr;
  }

  function tryParseJson(str) {
    // Use fixJsonString to preprocess the JSON string
    const fixedStr = fixJsonString(str);

    try {
      // Attempt to parse the fixed string
      return JSON.parse(fixedStr);
    } catch (error) {
      // Log the error if parsing fails
      console.error("JSON parse error:", error);
      return null;
    }
  }*/

  function fixJsonString(str) {
    // Trim the input string and handle missing quotes around keys
    let fixedStr = str
      .trim()
      .replace(/([{,])\s*([a-zA-Z0-9_]+)\s*:/g, '$1"$2":') // Add missing quotes around keys
      .replace(/,\s*$/, ""); // Remove trailing comma if present

    // Add missing quotes around string values (e.g., "flag": red → "flag": "red")
    fixedStr = fixedStr.replace(/:\s*([a-zA-Z0-9_]+)([,\}])/g, ': "$1"$2');

    // Fix mismatched or missing quotes (e.g., {"id":"value",flag":"red"} → {"id":"value","flag":"red"})
    fixedStr = fixedStr.replace(/(["'])([^"':,{}\[\]]+)(["'])/g, '"$2"');

    // Fix missing colons between keys and values (e.g., "file_full" "value" → "file_full": "value")
    fixedStr = fixedStr.replace(
      /(["'])([a-zA-Z0-9_]+)(["'])\s+(["'])/g,
      '"$2": $4'
    );

    // Fix invalid key-value pairs like `json 0` (e.g., json 0 → "json": "0")
    fixedStr = fixedStr.replace(
      /(\s|,)([a-zA-Z0-9_]+)\s+([a-zA-Z0-9_]+)/g,
      '$1"$2": "$3"'
    );

    // Check if the JSON string is missing a closing brace
    if (fixedStr[fixedStr.length - 1] !== "}") {
      fixedStr += "}";
    }

    return fixedStr;
  }

  function tryParseJson(str) {
    const fixedStr = fixJsonString(str);
    try {
      return JSON.parse(fixedStr);
    } catch (error) {
      console.error("JSON parse error:", error);
      return null;
    }
  }

  async function submitChat(content) {
    try {
      const message = {
        id: uuidv4(),
        role: "user",
        content: content,
      };
      let newMessages = [...messages, message];
      setMessages(newMessages);

      const response = await gptAPI.post(
        teach
          .map((t) => ({ role: "system", content: t.content }))
          .concat(
            newMessages.slice(-8).map((message) => ({
              role: message.role,
              content: message.content,
            }))
          )
      );

      //console.log(teach);
      //console.log("API Response:", response);

      function isValidJSON(jsonString) {
        try {
          JSON.parse(jsonString);
          return true; // JSON is valid
        } catch (error) {
          return false; // JSON is invalid
        }
      }

      const isValid = isValidJSON(response.content);
      let parsedContent = "";

      if (isValid) parsedContent = JSON.parse(response.content);
      // Attempt to fix and parse the content
      else parsedContent = tryParseJson(response.content);
      //console.log(parsedContent);
      if (parsedContent && parsedContent.json == 0) {
        //console.log("Parsed JSON:", parsedContent);
        handleRemove(parsedContent);
      } else {
        console.log("Plain text response:", response.content);
      }

      if (parsedContent && parsedContent.json == 0) {
        // console.log("inside");
        //console.log(response.content);
        const content = JSON.parse(response.content);
        const val = {
          content: content.summery_prompt,
          id: response.id,
          role: response.role,
        };
        //val.contant = response.content.summery_prompt;
        // console.log(val);
        newMessages = [...messages, message, val];
      } else if (parsedContent && parsedContent.json == 1) {
        ///// call to other function  for the relevalt jon in the server
        /////////////////////////////////////////////////////////////////
        //console.log(response.content);
        const value = await getServerResult(response.content);
        //console.log(value);
        const content = JSON.parse(response.content);

        const val = {
          content: value.content, //content.summery_prompt,
          id: response.id,
          role: response.role,
        };

        newMessages = [...messages, message, val];
      } else newMessages = [...messages, message, response];
      setMessages(newMessages);
      //console.log(newMessages);
      if (speak.isSpeak) {
        speaker.speak(response.content);
      }
    } catch (error) {
      console.error("Error in submitChat:", error);
    }
  }

  //handleSubmitJson
  //submitChat
  return (
    <StyledCover id="cover">
      {/*<NewUpdate />*/}
      <Grid container sx={{ height: "100%" }} id="container">
        <Grid
          item
          sx={{ display: { xs: "none", md: "block" } }}
          md={0.5}
        ></Grid>
        <Grid item md={11} display="flex" flexDirection="row">
          <StyledChatBox id="chatbox" sx={{ backgroundColor: "inherit" }}>
            {!demoMode && seachAction && <LeftDrawer />}
            {assistant && <MidDrawer name={currentJob} />}
            {chat && <MidDrawer name={currentJob} />}
            <RightDrawer />
            {!assistant && (
              <ChatBoard
                messages={messages}
                loading={gptAPI.loading}
                chunkMessage={gptAPI.chunkMessage}
              />
            )}
            {assistant && (
              <ChatBoardAssistant
                messages={messages}
                loading={assistantLoading}
              />
            )}
            {chat && showSend && (
              <ChatForm
                onSubmit={teach[0].json == 1 ? handleSubmitJson : submitChat}
              />
            )}
            {assistant && showSend && <ChatForm onSubmit={searchFiles} />}
            <ScrollDownButton />
          </StyledChatBox>
        </Grid>
        <Grid
          item
          sx={{ display: { xs: "none", md: "block" } }}
          md={0.5}
        ></Grid>
      </Grid>
    </StyledCover>
  );
};

export default ChatGPT;
