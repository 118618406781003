import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const savedTeachList = localStorage.getItem("teachList");
const InitialState = savedTeachList ? JSON.parse(savedTeachList) : [];

const TeachSlice = createSlice({
  name: "teach",
  initialState: InitialState,
  reducers: {
    addTeach(state, action) {
      const id = uuidv4();
      const newTeach = {
        id: id,
        content: "",
        rule_id: "",
        json: "",
      };
      state.push(newTeach);
      updateTeachLocalStorage([...state]);
    },
    updateTeach(state, action) {
      const { id, content, rule_id, json } = action.payload;
      const indexToUpdate = state.findIndex((item) => item.id === id);
      if (indexToUpdate !== -1) {
        const newTeach = { id, content, rule_id, json };
        state[indexToUpdate] = newTeach;
      }
      updateTeachLocalStorage([...state]);
    },
    removeTeach(state, action) {
      const id = action.payload;
      const indexToRemove = state.findIndex((item) => item.id === id);
      if (indexToRemove !== -1) {
        state.splice(indexToRemove, 1);
      }
      updateTeachLocalStorage([...state]);
    },
  },
});

function updateTeachLocalStorage(listTeach) {
  localStorage.setItem("teachList", JSON.stringify(listTeach));
}

export const teachAction = TeachSlice.actions;
export default TeachSlice;

/*

import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const TeachSlice = createSlice({
  name: "teach",
  initialState: [], // Start with an empty array, no localStorage dependency
  reducers: {
    addTeach(state, action) {
      const id = uuidv4();
      const newTeach = {
        id: id,
        content: "",
        rule_id: "",
        json: "",
      };
      state.push(newTeach); // Directly update the Redux state
    },
    updateTeach(state, action) {
      const { id, content, rule_id, json } = action.payload;
      const indexToUpdate = state.findIndex((item) => item.id === id);
      if (indexToUpdate !== -1) {
        state[indexToUpdate] = { id, content, rule_id, json };
      }
    },
    removeTeach(state, action) {
      const id = action.payload;
      const indexToRemove = state.findIndex((item) => item.id === id);
      if (indexToRemove !== -1) {
        state.splice(indexToRemove, 1);
      }
    },
    resetTeach(state) {
      return []; // Reset the state to an empty array
    },
  },
});

export const teachAction = TeachSlice.actions;
export default TeachSlice.reducer;*/
