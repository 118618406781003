import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  Divider,
  Drawer,
  Fab,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState, useEffect } from "react";
import Conversation from "./Conversation";
import ListConversation from "./ListConversation";
import ListTeach from "./ListTeach";

import { ToastContainer, toast } from "react-toastify";

import packageJson from "../../../../package.json";

import QrReader from "../../../Component/Qr/QrReader";

import Search from "../../../Component/Search/Search";

import { useDispatch, useSelector } from "react-redux";
import { gptAction } from "../../../Redux/GPTSlice"; //

import geturlparams_app from "../../../geturlparams_app";

import { useNavigate } from "react-router-dom";

function LeftDrawer() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [isQr, setIsQr] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const [infoQr, setInfoQr] = useState("");

  const [assistantId, setAssistantId] = useState(null);
  const [assistants, setAssistants] = useState([]); // Store assistants here
  const [activeTeachName, setActiveTeach] = useState(""); // Store assistants here
  const [showAssistansTable, setShowAssistansTable] = useState(false); // Store assistants here

  const dispatch = useDispatch();
  const showSend = useSelector((redux) => redux.gpt.showSend); // change to send

  const qrMode = useSelector((redux) => redux.gpt.qrMode); // change to send
  const assistantUpload = useSelector((redux) => redux.gpt.assistantUpload); // change to send

  const conditionAssistant = useSelector((redux) => redux.gpt.assistant); // change to send

  const chat = useSelector((redux) => redux.gpt.chat); // change to send
  const teach = useSelector((redux) => redux.teach);

  const listTeachActive = useSelector((redux) => redux.teach);
  const [listTeach, setlistTeach] = useState(
    JSON.parse(localStorage.getItem("rulesList")) || []
  );

  //console.log(listTeach); // sll rules
  //console.log(teach); // current active rule
  useEffect(() => {
    console.log(listTeach);
    /* const activeTeach = listTeach.find(
      (obj) => obj.id == listTeachActive[0].rule_id
    );*/
    const activeTeach = listTeach.find((obj) =>
      listTeachActive.some((activeObj) => activeObj.rule_id === obj.id)
    );

    console.log(activeTeach);
    if (activeTeach != undefined) setActiveTeach(activeTeach.content);
  }, [listTeach]);

  function convertToJSON(str) {
    const obj = {};
    const keyValuePairs = str.split(",");

    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split(":").map((item) => item.trim());
      obj[key] = isNaN(value) ? value : Number(value); // Convert to number if it's numeric
    });

    return obj;
  }

  const getAssistant = async () => {
    try {
      const urlApp = geturlparams_app();
      //  console.log(urlApp);

      const Info = {
        cmd: "get_assistant",
        b2b_id: urlApp.product,
      };
      let urlBase =
        localStorage.getItem("phpServer") + "/api/assistant_get.php";
      const resAssistant = await fetch(urlBase, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Info),
      });

      const jsonResponse = await resAssistant.json();

      //  console.log(jsonResponse);
      setAssistants(jsonResponse.results);
    } catch (error) {
      console.error("Failed to fetch B2B data:", error);
    }
  };

  useEffect(() => {
    /* const getAssistant = async () => {
      try {
        const urlApp = geturlparams_app();
        console.log(urlApp);

        const Info = {
          cmd: "get_assistant",
          b2b_id: urlApp.product,
        };
        let urlBase =
          localStorage.getItem("phpServer") + "/api/assistant_get.php";
        const resAssistant = await fetch(urlBase, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Info),
        });

        const jsonResponse = await resAssistant.json();

        console.log(jsonResponse);
        setAssistants(jsonResponse.results);
      } catch (error) {
        console.error("Failed to fetch B2B data:", error);
      }
    };*/

    getAssistant();
  }, []); // Empty dependency array: runs only on mount

  const [selectedAssistantId, setSelectedAssistantId] = useState(null);

  const handleAssistantClick = (assistant) => {
    console.log("Assistant clicked:", assistant);
    dispatch(gptAction.toggleAssistant(true)); // change to qr
    dispatch(gptAction.toggleAssistantID(assistant.assistant_id)); // change to qr
    dispatch(gptAction.toggleThreadID(assistant.thread_id)); // change to qr
    setSelectedAssistantId(assistant.id); // Track the selected assistant
    dispatch(gptAction.toggleAssistantName(assistant.name)); // change to qr

    setIsOpen(false);
  };

  const handleShowAssistant = () => {
    dispatch(gptAction.toggleChat(false)); // change to qr

    setShowAssistansTable(true);
  };
  ///////////////////

  useEffect(() => {
    if (isQr) {
      setIsQr(false);

      console.log(infoQr);
      console.log(convertToJSON(infoQr));
      localStorage.setItem("qrInfo", JSON.stringify(convertToJSON(infoQr)));
      dispatch(gptAction.toggleShowSend(true)); // change to qr

      setInfoQr("");
    }
  }, [infoQr != ""]);

  const handleDrawerOpen = () => {
    setIsOpen(true);
    getAssistant();
    setIsSearch(false);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const handleQr = () => {
    setIsQr(!isQr);
    setIsOpen(false);
    /*  if (isQr == false) setIsQr(true);
    else setIsQr(false);*/
  };

  const handleSearch = () => {
    /*setIsSearch(!isSearch);
    setIsOpen(false);*/

    navigate("/newassistant");
  };

  const handleChat = () => {
    dispatch(gptAction.toggleChat(true)); // change to qr
    dispatch(gptAction.toggleAssistant(false)); // change to qr
    setShowAssistansTable(false);
    setSelectedAssistantId("");
    setIsOpen(false);
  };

  return (
    <>
      <Fab
        onClick={handleDrawerOpen}
        sx={{
          position: "absolute",
          left: "5px",
          color: "red",
          top: 5,
          zIndex: 999,
          opacity: 1,
        }}
        size="small"
      >
        <MenuIcon />
      </Fab>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-root": {
            position: "absolute",
          },
          "& .MuiPaper-root": {
            position: "absolute",
          },
          overflow: "scroll",
        }}
        PaperProps={{
          sx: {
            width: 250,
            background: "#242526",
            opacity: 1,
            color: "white",
            padding: 3,
          },
        }}
        ModalProps={{
          container: document.getElementById("chatbox"),
          style: { position: "absolute", opacity: 1 },
        }}
        transitionDuration={{ appear: 700, enter: 700, exit: 300 }}
      >
        {/*<Stack direction="column" spacing={1}>
          <Button
            variant="outlined"
            sx={{ color: "white" }}
            size="large"
            onClick={() =>
              alert(
                `Due to local storage limit of 5MB, my application does not support multiple conversations on local storage. Please log in to use this feature. (This feature is coming soon!)`
              )
            }
          >
            New Chat
          </Button>
        <ListConversation /> 
        </Stack>*/}
        {packageJson.name} : {packageJson.version} {activeTeachName}
        {qrMode && (
          <Button
            variant="outlined"
            sx={{ color: "white" }}
            //size="large"
            onClick={handleQr}
          >
            Scan QR
          </Button>
        )}
        <Button
          variant="outlined"
          sx={{ color: "white" }}
          //size="large"
          onClick={handleChat}
        >
          chat
        </Button>
        {assistantUpload && (
          <Button
            variant="outlined"
            sx={{ color: "white" }}
            //size="large"
            onClick={handleSearch}
          >
            New Assistant
          </Button>
        )}
        {assistants.length > 0 && (
          <Button
            variant="outlined"
            sx={{ color: "white" }}
            //size="large"
            onClick={handleShowAssistant}
          >
            Assistants {assistants.length}
          </Button>
        )}
        <Divider color="white" sx={{ marginTop: 10, marginBottom: 2 }} />
        {/*}  {chat && (
          <Stack spacing={2}>
            <ListTeach />
          </Stack>
        )}*/}
        {showAssistansTable && (
          <TableContainer sx={{ maxHeight: 300, overflowY: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Assistant Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assistants.map((assistant) => (
                  <TableRow
                    key={assistant.id}
                    hover
                    onClick={() => {
                      if (!conditionAssistant) {
                        toast("Assistant is blocked");
                        return;
                      } // Disable onClick based on the condition
                      handleAssistantClick(assistant);
                    }}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      sx={{
                        color:
                          selectedAssistantId === assistant.id
                            ? "orange"
                            : "white",
                        backgroundColor:
                          selectedAssistantId === assistant.id
                            ? "gray"
                            : "transparent",
                      }}
                    >
                      {assistant.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Drawer>

      {isQr == true && <QrReader setInfoQr={setInfoQr} />}
      {isSearch == true && <Search setIsSearch={setIsSearch} />}
    </>
  );
}

export default LeftDrawer;
