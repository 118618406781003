/*import styled from "@emotion/styled";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  dracula,
  gruvboxDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import cumeoLogo from "../../Static/Images/Logo/cumeo128.png";
import twistedLogo from "../../Static/Images/Logo/twistedLogo.jpg";
const StyledChatItem = styled(ListItem)({
  backgroundColor: "#444654",
  marginBlock: "10px",
  borderRadius: "5px",
});

const ChatItem = (props) => {
  const showAvatar = useSelector((redux) => redux.gpt.showAvatar);
  const { message } = props;
  const codeBlockRegex = /```([\s\S]*?)```/;
  const strings = message.content.split(codeBlockRegex);

  console.log(strings);
  return (
    <StyledChatItem>
      {showAvatar && (
        <ListItemAvatar sx={{ maxWidth: 50 }}>
          <Avatar
            src={message.role === "user" ? cumeoLogo : twistedLogo}
            alt="Avatar"
          />
        </ListItemAvatar>
      )}
      <Box>
        {strings.map((str, index) => {
          if (index % 2 === 0) {
            return str
              .split(`\n`)
              .map((text) => <ListItemText primary={text} />);
          } else {
            const code = str.replace(/```/g, "");
            const codeLanguage = code.split("\n")[0] || "javascript";
            return (
              <Box>
                <SyntaxHighlighter
                  language={codeLanguage}
                  style={dracula}
                  showLineNumbers={true}
                  // wrapLongLines={true}
                  customStyle={{
                    overflow: "scroll",
                    // maxWidth: "100%",
                    // fontSize: 12,
                  }}
                >
                  {code}
                </SyntaxHighlighter>
              </Box>
            );
          }
        })}
      </Box>
    </StyledChatItem>
  );
};
export default ChatItem;*/

/*
import styled from "@emotion/styled";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import cumeoLogo from "../../Static/Images/Logo/cumeo128.png";
import twistedLogo from "../../Static/Images/Logo/twistedLogo.jpg";

const StyledChatItem = styled(ListItem)({
  backgroundColor: "#444654",
  marginBlock: "10px",
  borderRadius: "5px",
});

const ChatItem = (props) => {
  const showAvatar = useSelector((redux) => redux.gpt.showAvatar);
  const { message } = props;
  const codeBlockRegex = /```([\s\S]*?)```/;
  const strings = message.content.split(codeBlockRegex);

  // Function to detect if a string is an image URL
  const isImageUrl = (url) => url.match(/\.(webp|jpeg|jpg|gif|png)$/i) != null;

  // renderContent function from your old code
  const renderContent = (line) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/;
    const youtubeRegex =
      /(https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+|https?:\/\/youtu\.be\/[\w-]+)/;
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Regex for plain URLs

    // Check for markdown-style links
    const match = line.match(linkRegex);
    if (match) {
      const textBeforeLink = line.slice(0, match.index);
      const linkText = match[1];
      const url = match[2];

      return (
        <span>
          {textBeforeLink}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "red", fontWeight: "bold" }}
          >
            {linkText}
          </a>
        </span>
      );
    }

    // Check for YouTube links
    const youtubeMatch = line.match(youtubeRegex);
    if (youtubeMatch) {
      const youtubeUrl = youtubeMatch[0];
      return (
        <span>
          <a
            href={youtubeUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", fontWeight: "bold" }}
          >
            Watch on YouTube
          </a>
        </span>
      );
    }

    // Check for plain URLs and convert them to clickable links
    const urlMatch = line.match(urlRegex);
    if (urlMatch) {
      return (
        <span>
          {line.split(urlRegex).map((part, index) =>
            urlRegex.test(part) ? (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "green", fontWeight: "bold" }}
              >
                {part}
              </a>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </span>
      );
    }

    // If no special handling is needed, return the text as is
    return <span>{line}</span>;
  };

  return (
    <StyledChatItem>
      {showAvatar && (
        <ListItemAvatar sx={{ maxWidth: 50 }}>
          <Avatar
            src={message.role === "user" ? cumeoLogo : twistedLogo}
            alt="Avatar"
          />
        </ListItemAvatar>
      )}
      <Box>
        {strings.map((str, index) => {
          if (index % 2 === 0) {
            return str
              .split(`\n`)
              .map((text, idx) => (
                <ListItemText key={idx} primary={renderContent(text)} />
              ));
          } else {
            const code = str.replace(/```/g, "");
            const codeLanguage = code.split("\n")[0] || "javascript";
            return (
              <Box key={index}>
                <SyntaxHighlighter
                  language={codeLanguage}
                  style={dracula}
                  showLineNumbers={true}
                  customStyle={{
                    overflow: "scroll",
                  }}
                >
                  {code}
                </SyntaxHighlighter>
              </Box>
            );
          }
        })}
      </Box>
    </StyledChatItem>
  );
};

export default ChatItem;
*/

/*
import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import CloseIcon from "@mui/icons-material/Close";
import cumeoLogo from "../../Static/Images/Logo/cumeo128.png";
import twistedLogo from "../../Static/Images/Logo/twistedLogo.jpg";

const StyledChatItem = styled(ListItem)({
  backgroundColor: "#444654",
  marginBlock: "10px",
  borderRadius: "5px",
});

const ChatItem = (props) => {
  const showAvatar = useSelector((redux) => redux.gpt.showAvatar);
  const { message } = props;
  const codeBlockRegex = /```([\s\S]*?)```/;
  const strings = message.content.split(codeBlockRegex);

  // State to manage the popup
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  // Function to open the popup with YouTube content
  const handleYoutubeClick = (url) => {
    setPopupContent(url);
    setOpenPopup(true);
  };

  // Function to close the popup
  const handleClose = () => {
    setOpenPopup(false);
  };

  // Function to render content with links
  const renderContent = (line) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/;
    const youtubeRegex =
      /(https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+|https?:\/\/youtu\.be\/[\w-]+)/;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Check for markdown-style links
    const match = line.match(linkRegex);
    if (match) {
      const textBeforeLink = line.slice(0, match.index);
      const linkText = match[1];
      const url = match[2];

      return (
        <span key={line}>
          {textBeforeLink}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "red", fontWeight: "bold" }}
          >
            {linkText}
          </a>
        </span>
      );
    }

    // Check for YouTube links
    const youtubeMatch = line.match(youtubeRegex);
    if (youtubeMatch) {
      const youtubeUrl = youtubeMatch[0];
      return (
        <span key={line}>
          <Button
            onClick={() => handleYoutubeClick(youtubeUrl)}
            style={{ color: "blue", fontWeight: "bold" }}
          >
            Watch on YouTube
          </Button>
        </span>
      );
    }

    // Check for plain URLs and convert them to clickable links
    const urlMatch = line.match(urlRegex);
    if (urlMatch) {
      return (
        <span key={line}>
          {line.split(urlRegex).map((part, index) =>
            urlRegex.test(part) ? (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "green", fontWeight: "bold" }}
              >
                {part}
              </a>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </span>
      );
    }

    // If no special handling is needed, return the text as is
    return <span>{line}</span>;
  };

  return (
    <>
      <StyledChatItem>
        {showAvatar && (
          <ListItemAvatar sx={{ maxWidth: 50 }}>
            <Avatar
              src={message.role === "user" ? cumeoLogo : twistedLogo}
              alt="Avatar"
            />
          </ListItemAvatar>
        )}
        <Box>
          {strings.map((str, index) => {
            if (index % 2 === 0) {
              return str
                .split(`\n`)
                .map((text, idx) => (
                  <ListItemText key={idx} primary={renderContent(text)} />
                ));
            } else {
              const code = str.replace(/```/g, "");
              const codeLanguage = code.split("\n")[0] || "javascript";
              return (
                <Box key={index}>
                  <SyntaxHighlighter
                    language={codeLanguage}
                    style={dracula}
                    showLineNumbers={true}
                    customStyle={{
                      overflow: "scroll",
                    }}
                  >
                    {code}
                  </SyntaxHighlighter>
                </Box>
              );
            }
          })}
        </Box>
      </StyledChatItem>

  
      <Dialog open={openPopup} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          YouTube Video
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {popupContent && (
            <iframe
              src={`https://www.youtube.com/embed/${new URL(
                popupContent
              ).searchParams.get("v")}`}
              width="100%"
              height="400px"
              title="YouTube Video"
              style={{ border: 0 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatItem;*/

/*

import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  ImageListItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import CloseIcon from "@mui/icons-material/Close";
import cumeoLogo from "../../Static/Images/Logo/cumeo128.png";
import twistedLogo from "../../Static/Images/Logo/twistedLogo.jpg";

const StyledChatItem = styled(ListItem)({
  backgroundColor: "#444654",
  marginBlock: "10px",
  borderRadius: "5px",
});

const ChatItem = (props) => {
  const showAvatar = useSelector((redux) => redux.gpt.showAvatar);
  const { message } = props;
  const codeBlockRegex = /```([\s\S]*?)```/;
  const strings = message.content.split(codeBlockRegex);

  // State to manage the popup
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  // Function to open the popup with YouTube content
  const handleYoutubeClick = (url) => {
    setPopupContent(url);
    setOpenPopup(true);
  };

  // Function to close the popup
  const handleClose = () => {
    setOpenPopup(false);
  };

  // Function to detect if a string is an image URL
  const isImageUrl = (url) => /\.(webp|jpeg|jpg|gif|png)$/i.test(url);

  // Function to render content with links, images, or YouTube videos
  const renderContent = (line) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/;
    const youtubeRegex =
      /(https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+|https?:\/\/youtu\.be\/[\w-]+)/;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Check for markdown-style links
    const match = line.match(linkRegex);
    if (match) {
      const textBeforeLink = line.slice(0, match.index);
      const linkText = match[1];
      const url = match[2];

      if (isImageUrl(url)) {
        // If the URL is an image, render the image
        return (
          <span key={line}>
            {textBeforeLink}
            <ImageListItem>
              <img
                src={url}
                alt={linkText}
                loading="lazy"
                style={{ maxWidth: "100%", height: "auto", marginTop: "10px" }}
              />
            </ImageListItem>
          </span>
        );
      }

      return (
        <span key={line}>
          {textBeforeLink}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "red", fontWeight: "bold" }}
          >
            {linkText}
          </a>
        </span>
      );
    }

    // Check for YouTube links
    const youtubeMatch = line.match(youtubeRegex);
    if (youtubeMatch) {
      const youtubeUrl = youtubeMatch[0];
      return (
        <span key={line}>
          <Button
            onClick={() => handleYoutubeClick(youtubeUrl)}
            style={{ color: "blue", fontWeight: "bold" }}
          >
            Watch on YouTube
          </Button>
        </span>
      );
    }

    // Check for plain URLs
    const urlMatch = line.match(urlRegex);
    if (urlMatch) {
      return (
        <span key={line}>
          {line.split(urlRegex).map((part, index) => {
            if (urlRegex.test(part)) {
              if (isImageUrl(part)) {
                // If the URL is an image, render the image directly
                return (
                  <ImageListItem key={index}>
                    <img
                      src={part}
                      alt="Image"
                      loading="lazy"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginTop: "10px",
                      }}
                    />
                  </ImageListItem>
                );
              }
              // Render regular URL as a clickable link
              return (
                <a
                  key={index}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "green", fontWeight: "bold" }}
                >
                  {part}
                </a>
              );
            }
            return <span key={index}>{part}</span>;
          })}
        </span>
      );
    }

    // If no special handling is needed, return the text as is
    return <span key={line}>{line}</span>;
  };

  return (
    <>
      <StyledChatItem>
        {showAvatar && (
          <ListItemAvatar sx={{ maxWidth: 50 }}>
            <Avatar
              src={message.role === "user" ? cumeoLogo : twistedLogo}
              alt="Avatar"
            />
          </ListItemAvatar>
        )}
        <Box>
          {strings.map((str, index) => {
            if (index % 2 === 0) {
              return str
                .split(`\n`)
                .map((text, idx) => (
                  <ListItemText key={idx} primary={renderContent(text)} />
                ));
            } else {
              const code = str.replace(/```/g, "");
              const codeLanguage = code.split("\n")[0] || "javascript";
              return (
                <Box key={index}>
                  <SyntaxHighlighter
                    language={codeLanguage}
                    style={dracula}
                    showLineNumbers={true}
                    customStyle={{
                      overflow: "scroll",
                    }}
                  >
                    {code}
                  </SyntaxHighlighter>
                </Box>
              );
            }
          })}
        </Box>
      </StyledChatItem>

   
      <Dialog open={openPopup} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          YouTube Video
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {popupContent && (
            <iframe
              src={`https://www.youtube.com/embed/${new URL(
                popupContent
              ).searchParams.get("v")}`}
              width="100%"
              height="400px"
              title="YouTube Video"
              style={{ border: 0 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatItem;
*/

import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  ImageListItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import CloseIcon from "@mui/icons-material/Close";
import cumeoLogo from "../../Static/Images/Logo/cumeo128.png";
import twistedLogo from "../../Static/Images/Logo/twistedLogo.jpg";

const StyledChatItem = styled(ListItem)({
  backgroundColor: "#444654",
  marginBlock: "10px",
  borderRadius: "5px",
});

const ChatItem = (props) => {
  const showAvatar = useSelector((redux) => redux.gpt.showAvatar);
  const { message } = props;
  const codeBlockRegex = /```([\s\S]*?)```/;
  const strings = message.content.split(codeBlockRegex);

  // State to manage the popup
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  // Function to open the popup with YouTube content
  const handleYoutubeClick = (url) => {
    setPopupContent(url);
    setOpenPopup(true);
  };

  // Function to close the popup
  const handleClose = () => {
    setOpenPopup(false);
  };

  // Function to detect if a string is an image URL
  const isImageUrl = (url) => /\.(webp|jpeg|jpg|gif|png)$/i.test(url);

  // Function to render content with links, images, or YouTube videos
  const renderContent = (line) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/;
    const youtubeRegex =
      /(https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+|https?:\/\/youtu\.be\/[\w-]+)/;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Check for markdown-style links
    const match = line.match(linkRegex);
    if (match) {
      const textBeforeLink = line.slice(0, match.index);
      const linkText = match[1];
      const url = match[2];

      // Check if the link is a YouTube link
      if (youtubeRegex.test(url)) {
        return (
          <span key={line}>
            {textBeforeLink}
            <Button
              onClick={() => handleYoutubeClick(url)}
              style={{ color: "blue", fontWeight: "bold" }}
            >
              {linkText}
            </Button>
          </span>
        );
      }

      if (isImageUrl(url)) {
        // If the URL is an image, render the image
        return (
          <span key={line}>
            {textBeforeLink}
            <ImageListItem>
              <img
                src={url}
                alt={linkText}
                loading="lazy"
                style={{ maxWidth: "100%", height: "auto", marginTop: "10px" }}
              />
            </ImageListItem>
          </span>
        );
      }

      return (
        <span key={line}>
          {textBeforeLink}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "red", fontWeight: "bold" }}
          >
            {linkText}
          </a>
        </span>
      );
    }

    // Check for plain YouTube links
    const youtubeMatch = line.match(youtubeRegex);
    if (youtubeMatch) {
      const youtubeUrl = youtubeMatch[0];
      return (
        <span key={line}>
          <Button
            onClick={() => handleYoutubeClick(youtubeUrl)}
            style={{ color: "blue", fontWeight: "bold" }}
          >
            Watch on YouTube
          </Button>
        </span>
      );
    }

    // Check for plain URLs
    const urlMatch = line.match(urlRegex);
    if (urlMatch) {
      return (
        <span key={line}>
          {line.split(urlRegex).map((part, index) => {
            if (urlRegex.test(part)) {
              if (isImageUrl(part)) {
                // If the URL is an image, render the image directly
                return (
                  <ImageListItem key={index}>
                    <img
                      src={part}
                      alt="Image"
                      loading="lazy"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginTop: "10px",
                      }}
                    />
                  </ImageListItem>
                );
              }
              // Render regular URL as a clickable link
              return (
                <a
                  key={index}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "green", fontWeight: "bold" }}
                >
                  {part}
                </a>
              );
            }
            return <span key={index}>{part}</span>;
          })}
        </span>
      );
    }

    // If no special handling is needed, return the text as is
    return <span key={line}>{line}</span>;
  };

  return (
    <>
      <StyledChatItem>
        {showAvatar && (
          <ListItemAvatar sx={{ maxWidth: 50 }}>
            <Avatar
              src={message.role === "user" ? cumeoLogo : twistedLogo}
              alt="Avatar"
            />
          </ListItemAvatar>
        )}
        <Box>
          {strings.map((str, index) => {
            if (index % 2 === 0) {
              return str
                .split(`\n`)
                .map((text, idx) => (
                  <ListItemText key={idx} primary={renderContent(text)} />
                ));
            } else {
              const code = str.replace(/```/g, "");
              const codeLanguage = code.split("\n")[0] || "javascript";
              return (
                <Box key={index}>
                  <SyntaxHighlighter
                    language={codeLanguage}
                    style={dracula}
                    showLineNumbers={true}
                    customStyle={{
                      overflow: "scroll",
                    }}
                  >
                    {code}
                  </SyntaxHighlighter>
                </Box>
              );
            }
          })}
        </Box>
      </StyledChatItem>

      {/* Popup Dialog for YouTube video */}
      <Dialog open={openPopup} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          YouTube Video
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {popupContent && (
            <iframe
              src={`https://www.youtube.com/embed/${new URL(
                popupContent
              ).searchParams.get("v")}`}
              width="100%"
              height="400px"
              title="YouTube Video"
              style={{ border: 0 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatItem;
